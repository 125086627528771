import groq from "groq";
import {
  flattenRepeatedEvents,
  groqDateTime,
  groqEventStart,
} from "../../../../apps/frontend/src/components/arrangement/sanityQuery";
import { customCampaignIsOngoingGroqFilter } from "../../../../apps/frontend/src/components/campaigns/customCampaign/sanityQuery";
import { campaignIsInSeasonGroqFilter } from "../../../../apps/frontend/src/components/campaigns/sanityQuery";
import { digitalLibraryFilter } from "../../../../apps/frontend/src/components/digitalLibrary/sanityQuery";
import { filters, repeatedEventNotFinished } from "../filters";
import { templateCampaignTypes } from "../campaigns";

const aWeekInSeconds = 7 * 24 * 60 * 60;
const getPromoteNowFilter = (promotionPath = "") =>
  groq`(${promotionPath}promotion.startsAt < now() && ${promotionPath}promotion.endsAt > now())`;

const promoteNonRepeatedEventFilter = groq`
  eventStatus != "cancelled" && 
  (
    featured && 
    ${filters.eventNotFinished} &&
    (allDay || defined(eventStart)) &&
    defined(startDate) &&
    ${groqDateTime("startDate")} <= (${groqDateTime("$today")} + ${aWeekInSeconds}) ||
    ${getPromoteNowFilter()}
  )
`;

const promoteRepeatedEventOccurenceFilter = groq`
  (
    (defined(@.customization.eventStatus) && @.customization.eventStatus != "cancelled") ||
    (!defined(@.customization.eventStatus) && ^.eventStatus != "cancelled")
  ) &&
  ${repeatedEventNotFinished} &&
  (
    @.customization.featured &&
    (defined(@.customization.eventStart) || defined(^.eventStart)) &&
    ${groqDateTime("@.date")} <= (${groqDateTime("$today")} + ${aWeekInSeconds}) ||
    ${getPromoteNowFilter("@.customization.")}
  )
`;

// TODO fjerne når vi har migrert til promotion
const toggleFeaturedFilter = groq`(
  toggleFeatured.featured &&
  defined(toggleFeatured.featureFromDate) &&
  dateTime(toggleFeatured.featureFromDate) > (${groqDateTime("$today")} - ${aWeekInSeconds})
)`;

const orderByPromotion = groq`order(coalesce(promotion.endsAt, toggleFeatured.featureFromDate) desc)`;

export type PromotedItemsResolvers = Record<
  | "event"
  | "service"
  | "digitalLibraryService"
  | "list"
  | "recommendation"
  | "sommerles"
  | "adventCalendar"
  | "bibliobattles"
  | "customCampaign",
  string
>;

const eventsQuery = (resolver: string) => groq`*[
    _type == "event" && 
    select(repeated => count(repeatedDates[${promoteRepeatedEventOccurenceFilter}]) > 0, ${promoteNonRepeatedEventFilter})
  ] 
  ${flattenRepeatedEvents(promoteRepeatedEventOccurenceFilter)}
  | order(startDate asc, ${groqEventStart} asc)
  {${resolver}}`;

const servicesQuery = (resolver: string) =>
  groq`*[_type == "service" && (${toggleFeaturedFilter} || ${getPromoteNowFilter()})] | ${orderByPromotion} {${resolver}}`;

const digitalLibraryServicesQuery = (resolver: string) =>
  groq`*[${digitalLibraryFilter} && (${toggleFeaturedFilter} || ${getPromoteNowFilter()})] | ${orderByPromotion} {${resolver}}`;

const listsAndRecommendationsQuery = (
  resolvers: PromotedItemsResolvers
) => groq`*[_type in ["list", "recommendation"] && ${getPromoteNowFilter()}] | order(coalesce(promotion.endsAt, _createdAt) desc) {
  _type == "list" => {${resolvers.list}},
  _type == "recommendation" => {${resolvers.recommendation}}
}`;

const templateCampaignsQuery = (resolvers: PromotedItemsResolvers) => groq`*[_type in ${JSON.stringify(
  templateCampaignTypes
)} && ${campaignIsInSeasonGroqFilter}] | order(startDate) {
  _type == "sommerles" => {${resolvers.sommerles}},
  _type == "adventCalendar" => {${resolvers.adventCalendar}},
  _type == "bibliobattles" => {${resolvers.bibliobattles}},
}`;

const customCampaignsQuery = (resolver: string) =>
  groq`*[_type == "customCampaign" && (${customCampaignIsOngoingGroqFilter} || ${getPromoteNowFilter()})] | order(startDate) {${resolver}}`;

/**
 * NOTE: Update docs when making changes: https://bs-redia.atlassian.net/l/cp/e54CX5CS
 */
export const getPotentialPromotedItemsQuery = (maxNoItems: number, resolvers: PromotedItemsResolvers) => groq`
  [
    ...${templateCampaignsQuery(resolvers)},
    ...${customCampaignsQuery(resolvers.customCampaign)},
    ...${eventsQuery(resolvers.event)},
    ...${servicesQuery(resolvers.service)},
    ...${digitalLibraryServicesQuery(resolvers.digitalLibraryService)},
    ...${listsAndRecommendationsQuery(resolvers)},
  ]
  [defined(@)] // Filter out null-entries
  [0...${maxNoItems}]
`;

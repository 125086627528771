import { PortableTextTextBlock } from "@sanity/types";
import { AdventCalendar, Event, Library, Publication, SanityBlock } from "./libryContentSanityTypes";
import { ArrayElement } from "./typeUtils";

type CustomBlock = { _type: "table" | "gallery" | "videoEmbed" | "linkButton" };

export type Blocks = (PortableTextTextBlock | SanityBlock | CustomBlock)[];

export type CustomizableRepeatedEventField = keyof Pick<
  Event,
  "eventStart" | "eventEnd" | "eventStatus" | "teaser" | "body" | "promotion" | "featured"
>;

export const customizableRepeatedEventFields: CustomizableRepeatedEventField[] = [
  "eventStart",
  "eventEnd",
  "eventStatus",
  "teaser",
  "body",
];

export type AdventCalendarDoor = ArrayElement<NonNullable<AdventCalendar["doors"]>>;

export type AdventCalendarDoorType = AdventCalendarDoor["_type"];

export const isPublication = (item: unknown): item is Publication =>
  !!item && typeof item === "object" && (item as any)?.["_type"] === "publication";

export const isLibrary = (item: unknown): item is Library =>
  !!item && typeof item === "object" && (item as any)?.["_type"] === "library";

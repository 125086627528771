import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { UTCDateToNorwegian } from "../date/date";

export const isCampaignSeason = ({
  teaserStartDate,
  startDate,
  endDate,
  applyTeaserStartDate = false,
}: {
  teaserStartDate?: string;
  startDate?: string;
  endDate?: string;
  applyTeaserStartDate?: boolean;
}): boolean => {
  if (!startDate || !endDate) return false;

  const now = new Date();
  const thisYear = now.getFullYear();
  const effectiveStartDate = applyTeaserStartDate ? teaserStartDate : startDate;
  const start = UTCDateToNorwegian(new Date(`${thisYear}-${effectiveStartDate}`));
  const end = UTCDateToNorwegian(new Date(`${thisYear}-${endDate}`));

  const daysUntilStart = differenceInCalendarDays(start, now);
  const daysSinceEnd = differenceInCalendarDays(now, end);

  return daysUntilStart <= 0 && daysSinceEnd < 1;
};

import { Modify } from "@biblioteksentralen/types";
import { CustomCampaign } from "@libry-content/types";
import { groq } from "next-sanity";
import { ResolvedEvent, resolveEventGroqProjection } from "../../arrangement/sanityQuery";

const customCampaignIsStarted = groq`coalesce(teaserStartDate, startDate) <= $today`;

export const customCampaignIsNotFinished = groq`endDate >= $today`;

export const customCampaignIsOngoingGroqFilter = groq`($showCampaignOutsideSeason || (${customCampaignIsStarted} && ${customCampaignIsNotFinished}))`;

export const customCampaignGroqFilter = groq`
  _type == "customCampaign" &&
  ${customCampaignIsOngoingGroqFilter}
`;

// Include draft ids, for use with useCampaignPreviewData and unpublished campaigns
export const customCampaignWithDraftsGroqFilter = groq`
  _type == "customCampaign" &&
  _id in [$customCampaignId, "drafts." + $customCampaignId] &&
  ${customCampaignIsOngoingGroqFilter}
`;

export const resolveCustomCampaignGroqProjecton = groq`
...,
"events": events[]->{
  ${resolveEventGroqProjection}
},
`;

export type ResolvedCustomCampaign = Modify<
  CustomCampaign,
  {
    events: ResolvedEvent[] | null;
  }
>;

export const resolveCustomCampaignBannerDataGroqProjection = groq`_id, _type, teaserStartDate, startDate, endDate, image, title, teaser, promotion`;

export type ResolvedCustomCampaignBannerData = Pick<
  CustomCampaign,
  "_id" | "_type" | "teaserStartDate" | "startDate" | "endDate" | "image" | "title" | "teaser" | "promotion"
>;
